<template>
  <header class="app-header">
    <div class="container">
      <!-- 使用头部导航组件 -->
      <AppHeaderNav />
      <div class="language">
        <div @mouseover="onblur"  class="languageitem">
          <img src="../assets/images/user.svg" />
          {{ langtype }}</div>
        <div class="section_3" v-if="isshow" @mouseover="onblur"  @mouseleave="onleave">
          <div :class="langtype == '繁体中文' ? 'text-wrapper_1 flex-col checked' : 'text-wrapper_1 flex-col'" @click="languageclick('繁体中文')">
            <span class="text_17">繁体中文</span>
            <span class="text_18 eng">Traditional&nbsp;Chinese</span>
          </div>
          <div :class="langtype == '简体中文' ? 'text-wrapper_2 flex-col checked' : 'text-wrapper_2 flex-col'" @click="languageclick('简体中文')">
            <span class="text_19">简体中文</span>
            <span class="text_20 eng">Simplified&nbsp;Chinese</span>
          </div>
          <div :class="langtype == 'English' ? 'text-wrapper_3 flex-col checked' : 'text-wrapper_3 flex-col'" @click="languageclick('English')">
            <div class="text_21">English</div>
            <div class="text_22 eng">English</div>
          </div>
          <!-- 日本語 -->
          <div :class="langtype == '日本語' ? 'text-wrapper_4 flex-col checked' : 'text-wrapper_4 flex-col'" @click="languageclick('日本語')">
            <div class="text_23">日本語</div>
            <div class="text_24 eng">Japanese</div>
          </div>
        </div>
      </div>
      <!-- 使用头部购物车组件 -->
      <!-- <AppHeaderCart /> -->
    </div>
  </header>
</template>

<script>
import store from '@/store'
import { ref } from 'vue'
import AppHeaderNav from './app-header-nav'
import router from '@/router'

export default {
  name: 'AppHeader',
  components: { AppHeaderNav },
  setup () {
    const isshow = ref(false)
    const langtype = ref(store.state.home.type)
    const onblur = () => {
      isshow.value = true
    }
    const onleave = () => {
      isshow.value = false
      // console.log('dddd')
    }
    const goindex = () => {
      router.push('/')
    }
    const languageclick = (type) => {
      store.commit('home/setType', type)
      langtype.value = store.state.home.type
      // history.go(0)
    }
    return { onblur, isshow, onleave, langtype, languageclick, goindex }
  }
}
</script>

<style scoped lang='less'>
.app-header {
  background: rgba(255, 255, 255, 1);
  height: 198px;
  // background: url(../assets/images/bg1.png) no-repeat;
  box-sizing: border-box;
  padding: 60px 0;
  position: relative;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .language {
    position: relative;
    width: 100px;
    margin-left: 60px;
    //  右浮动
    float: right;
    // width: 100px;
    display: block;
    // height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
    // line-height: 22px;
    padding-right: 0;
    margin-right: 0;

    .languageitem {
      display: flex;
      align-items: center;
      height: 16px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
    }

    .section_3 {
      box-shadow: 0px 0px 4px #EAF3FF;
      background: #fff;
      text-align: center;
      position: absolute;
      z-index: 20;
      top: 34px;
      left: -77px;
      font-size: 14px;
      padding: 8px;

      .flex-col {
        padding: 5px 26px;
        cursor: pointer;
        background: #F9FCFF;
        border-radius: 4px;
      }

      .flex-col+.flex-col {
        margin-top: 8px;
      }

      .eng {
        font-size: 10px;
        color: #999;
      }

      .checked {
        background: #141C42;
        color: white;
      }
    }
  }
}
</style>
